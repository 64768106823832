<template>
  <div>
    <b-card title="Vous pouvez voir les données de recherche ici.">
      <b-row class="mb-2">
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <flat-pickr
            v-model="rangeDate"
            placeholder="Choisissez une date"
            class="form-control mr-1"
            :config="{ mode: 'range'}"
          />
        </b-col>
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <b-card class="text-center">
            <b-avatar
              class="mb-1"
              :variant="`light-secondary`"
              size="45"
            >
              <feather-icon
                size="21"
                icon="SearchIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 v-if="datas" class="mb-25 font-weight-bolder">
                {{ datas.map(d => d.total).reduce((a, b) => a + b, 0) }}
              </h2>
              <span>Recherches</span>
            </div>
            <!-- <h6 v-if="onHold" class="transaction-title px-2 mb-1">+ {{ onHold }}€ en attente</h6>
            <span v-if="subStatistic"><strong>+{{ subStatistic.total.toFixed(0) }}€ CA</strong></span> -->
          </b-card>
        </b-col>
        <b-col
          lg="3"
          md="3"
          xs="12"
        >
          <b-card class="text-center">
            <b-avatar
              class="mb-1"
              :variant="`light-success`"
              size="45"
            >
              <feather-icon
                size="21"
                icon="NavigationIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 v-if="datas" class="mb-25 font-weight-bolder">
                {{ datas.map(d => d.products.length).reduce((a, b) => a + b, 0) }}
              </h2>
              <span>Clics Produit</span>
            </div>
            <h6 class="transaction-title text-primary px-2 mb-1">{{ (datas.map(d => d.products.length).reduce((a, b) => a + b, 0) / datas.map(d => d.total).reduce((a, b) => a + b, 0) * 100).toFixed(0) }}%</h6>
          </b-card>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="datas"
          :fields="fields"
        >
          <!-- <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template> -->

          <template #cell(_createdAt)="data">
            {{ $moment(data.item._createdAt).format('DD/MM/YYYY') }}
          </template>
          <!-- <template #cell(price)="data">
            <p>{{ data.item.price }}€</p>
          </template> -->
          <template #cell(products)="data">
            <p>{{ data.item.products && data.item.products.length ? data.item.products.length : 0 }} <span class="text-primary">({{ data.item.products && data.item.products.length ? (data.item.products.length / data.item.total * 100).toFixed(0) : 0 }}%)</span></p>
          </template>
          <!-- <template #cell(status)="data">
            {{ data }}
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BTable,
  VBPopover,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BAvatar,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      baseDatas: [],
      datas: [],
      data: {},
      fields: [{ key: '_id', label: 'term', sortable: true }, { label: 'Total', key: 'total', sortable: true }, { label: 'Clic Produit', key: 'products', sortable: true }],
      rangeDate: null,
      dataChart: null,
      loading: false,
    }
  },
  watch: {
    rangeDate: {
      handler: 'getDatas',
      deep: true,
    },
  },
  async mounted() {
    try {
      await this.getDatas()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getDatas() {
      try {
        const filter = {}
        if (this.rangeDate && this.rangeDate.includes('to')) {
          const dates = this.rangeDate.split(' to ')
          filter.createdAt = { $gte: new Date(dates[0]), $lte: new Date(dates[1]) }
        // eslint-disable-next-line no-irregular-whitespace
        }
        const {
          terms,
        } = await this.$http.post('/admin/search', { filter })
        this.datas = terms
        this.baseDatas = terms
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.datas = this.baseDatas
      } else {
        const value = this.code
        console.log(value)
        this.datas = this.baseDatas.filter(data => data.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
